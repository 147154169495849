import React, { useState, useEffect } from 'react';
import Footers from '../Components/Footers';
import Header from '../Components/Header';
import { listAgenda } from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';

export default function AgendaPage() {
    const [Agenda, setAgenda] = useState([]);
    const [activeEvent, setActiveEvent] = useState(null); // State to manage active event
    const client = generateClient();

    // Fetch agenda data from API
    const getAgenda = async () => {
        try {
            const listAgendaResponse = await client.graphql({
                query: listAgenda,
            });
            setAgenda(listAgendaResponse.data.listAgenda.items);
            console.log(listAgendaResponse.data.listAgenda.items);
        } catch (error) {
            console.error("Erreur lors de la récupération de l'agenda :", error);
        }
    };

    // Fetch the agenda data when the component mounts
    useEffect(() => {
        getAgenda();
    }, []);

    // Toggles the display of additional event details
    const toggleEventDetails = (eventId) => {
        setActiveEvent(activeEvent === eventId ? null : eventId);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow w-full flex flex-col items-center mt-6">
                <h1 className="text-2xl font-bold mb-4">Agenda</h1>
                <div className="w-11/12 max-w-3xl">
                    {Agenda.length === 0 ? (
                        <p className="text-gray-600 text-center mt-6 mb-6">Aucun événement disponible pour le moment.</p>
                    ) : (
                        Agenda.map(event => (
                            <div key={event.id} className="mb-4 p-4 bg-white shadow-md rounded-md">
                                <div onClick={() => toggleEventDetails(event.id)} className="cursor-pointer">
                                    {/* Event Name and Date */}
                                    <h2 className="text-xl font-semibold">{event.name}</h2>
                                    <p className="text-gray-600 mt-2">Date de l'événement : {new Date(event.date).toLocaleDateString()}</p>
                                    
                                    {/* Conditionally render the image if it exists */}
                                    {event.image && (
                                        <img 
                                            src={event.image} 
                                            alt={`${event.name} cover`} 
                                            className="mt-3 h-40 w-full object-cover rounded-md"
                                        />
                                    )}
                                </div>
                         
                                {/* Conditionally render extra notes when the event is clicked */}
                                {activeEvent === event.id && (
                                    <div className="mt-4 p-4 bg-gray-100 rounded-md">
                                        <h3 className="text-lg font-bold">Notes :</h3>
                                        <p>{event.description}</p> {/* Description content */}
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </main>
            <Footers />
        </div>
    );
}
