import React, { useState } from 'react';
import Footers from "../Components/Footers";
import Header from "../Components/Header";
import { useForm } from "react-hook-form";
import { generateClient } from '@aws-amplify/api';

export const createMessages = /* GraphQL */ `
  mutation CreateMessages(
    $input: CreateMessagesInput!
    $condition: ModelMessagesConditionInput
  ) {
    createMessages(input: $input, condition: $condition) {
      id
      mail
      nom
      note
      numero
      createdAt
      updatedAt
      __typename
    }
  }
`;

export default function ContactPage() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state

  const client = generateClient(); 

  const onSubmit = async (data) => {
    setIsSubmitting(true); // Set loading state
    try {
      const input = {
        mail: data.mail,
        nom: data.name,
        note: data.message,
        numero: data.numero, // Add phone number to the input
      };

      await client.graphql({
        query: createMessages,
        variables: { input },
      });

      setSuccessMessage("Votre message a été envoyé avec succès !");
      setErrorMessage(null);
      reset(); // Reset form fields after successful submission
    } catch (err) {
      setErrorMessage("Erreur lors de l'envoi du message : " + err.message);
      setSuccessMessage(null);
    } finally {
      setIsSubmitting(false); // Clear loading state
    }
  };

  return (
    <div className="flex flex-col min-h-screen"> {/* Flex container for layout */}
      <Header />
      
      <main className="flex-grow container mx-auto p-6"> {/* Flex-grow for main content */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-4">Informations de contact</h2>
          <div className="space-y-4">
            <p className="text-lg"><strong>Téléphone:</strong></p>
            <p className="text-gray-600">+237 679 678 406</p>
            <p className="text-gray-600">+237 697 682 375</p>
            
            <p className="text-lg"><strong>Emplacement:</strong></p>
            <p className="text-gray-600">Douala</p>

            <p className="text-lg"><strong>Email:</strong></p>
            <p className="text-gray-600">haussinj@yahoo.fr</p>
          </div>
        </div>

        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Envoyez-nous un message</h2>

          {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-lg font-medium text-gray-700">Nom</label>
              <input 
                type="text" 
                id="name" 
                {...register("name", { required: "Le Nom est requis" })}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
            </div>

            <div>
              <label htmlFor="mail" className="block text-lg font-medium text-gray-700">Email</label>
              <input 
                type="email" 
                id="mail" 
                {...register("mail", { required: "L'email est requis" })}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.mail ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.mail && <p className="text-red-500 text-sm">{errors.mail.message}</p>}
            </div>

            <div>
              <label htmlFor="numero" className="block text-lg font-medium text-gray-700">Numéro de téléphone</label>
              <input 
                type="tel" 
                id="numero" 
                {...register("numero", { 
                  required: "Le numéro de téléphone est requis",
                  pattern: {
                    value: /^[0-9+ ]+$/,
                    message: "Le numéro de téléphone doit contenir uniquement des chiffres ou des signes '+' et ' '"
                  }
                })}
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.numero ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.numero && <p className="text-red-500 text-sm">{errors.numero.message}</p>}
            </div>

            <div>
              <label htmlFor="message" className="block text-lg font-medium text-gray-700">Votre message</label>
              <textarea 
                id="message" 
                {...register("message", { required: "Le message est requis" })}
                rows="4" 
                className={`mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}
            </div>

            <button 
              type="submit" 
              disabled={isSubmitting}
              className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white ${isSubmitting ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isSubmitting ? "Envoi en cours..." : "Envoyer"}
            </button>
          </form>
        </section>
      </main>

      <Footers marginsTop={50} /> {/* Footer component */}
    </div>
  );
}
