import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { listMessages } from '../../graphql/queries';
import { generateClient } from 'aws-amplify/api';

export default function MessagePage() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const client = generateClient();

  // Fetch messages data from API
  const getMessages = async () => {
    setLoading(true);
    try {
      const listMessagesResponse = await client.graphql({
        query: listMessages,
      });
      setMessages(listMessagesResponse.data.listMessages.items);
    } catch (error) {
      console.error("Erreur lors de la récupération des messages :", error);
      setError("Erreur lors de la récupération des messages.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMessages(); // Fetch messages
  }, []);

  return (
    <main className="p-4">
      <Header pageName="Messages" isAdmin={true} />
      <h1 className="text-2xl font-bold mb-4">Messages</h1>

      {loading && <p className="text-gray-600">Chargement...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {messages.length === 0 && !loading && <p className="text-gray-600">Aucun message disponible.</p>}

      <div className="flex flex-col items-center">
        {messages.map((message) => (
          <div key={message.id} className="bg-gray-100 p-4 rounded mb-4 w-full max-w-md">
            <h2 className="text-xl font-semibold">{message.nom}</h2>
            <p className="text-gray-700 mt-2">Email: {message.mail}</p>
            <p className="text-gray-700 mt-2">Note: {message.note}</p>
            <p className="text-gray-700 mt-2">Numéro: {message.numero}</p> {/* Display phone number */}
            <p className="text-gray-500 mt-2 text-sm">Date: {new Date(message.createdAt).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </main>
  );
}
