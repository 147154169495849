import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { generateClient } from 'aws-amplify/api';
import { processImageLink } from '../../utils';

export default function Modal({ isOpen, onClose, userId }) {
  const { register, handleSubmit, control, watch, formState: { errors } } = useForm({
    defaultValues: {
      paragraphs: [{ text: '', imageLink: '', imageDescription: '', imagePosition: 'aucune' }],
      coverImage: { link: '' }
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'paragraphs',
  });

  const client = generateClient();
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const TopTabArray = ["Actualité", "Nouveauté", "Portrait", "Chronique", "Agenda"];

  if (!isOpen) return null;

 

  const onSubmitForm = async (data) => {
    setLoading(true);
    setSubmissionError(null);

    if (!userId) {
      setSubmissionError("L'ID utilisateur est requis. Assurez-vous que vous êtes connecté.");
      setLoading(false);
      return;
    }

 

    try {
      const articleData = {
        Titles: data.title,
        images: processImageLink(data.coverImage.link),
        userID: userId,
        rubrique: data.rubrique,
      };

      const newArticle = await client.graphql({
        query: createArticles,
        variables: { input: articleData },
      });

      const articleId = newArticle.data.createArticles.id;

      for (let i = 0; i < data.paragraphs.length; i++) {
        const paragraphData = {
          text: data.paragraphs[i].text,
          title: `Paragraphe ${i + 1}`,
          order: i + 1,
          articlesID: articleId,
        };

        const paragraphResponse = await client.graphql({
          query: createParagraph,
          variables: { input: paragraphData },
        });

        if (data.paragraphs[i].imageLink) {
          const imageData = {
            link:  processImageLink(data.paragraphs[i].imageLink),
            descriptions: data.paragraphs[i].imageDescription || `Image pour Paragraphe ${i + 1}`,
            position: data.paragraphs[i].imagePosition,
            ParagraphID: paragraphResponse.data.createParagraph.id,
          };

          await client.graphql({
            query: createImages,
            variables: { input: imageData },
          });
        }
      }

      console.log("Article créé avec succès");
      onClose();
    } catch (error) {
      console.error('Erreur lors de la création de l’article:', error);
      setSubmissionError('Échec de la création de l’article. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg w-96 p-6 relative">
        <h2 className="text-2xl font-bold mb-4">Créer un Article</h2>
        {submissionError && <p className="text-red-500 mb-4">{submissionError}</p>}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <input
            className="w-full mb-4 p-2 border rounded"
            type="text"
            placeholder="Titre"
            {...register('title', { required: 'Le titre est requis' })}
          />
          {errors.title && <p className="text-red-500">{errors.title.message}</p>}

          <select
            className="w-full mb-4 p-2 border rounded"
            {...register('rubrique', { required: 'La rubrique est requise' })}
          >
            <option value="">Sélectionnez la rubrique</option>
            {TopTabArray.map((rubrique, index) => (
              <option key={index} value={rubrique}>
                {rubrique}
              </option>
            ))}
          </select>
          {errors.rubrique && <p className="text-red-500">{errors.rubrique.message}</p>}

          <input
            className="w-full mb-4 p-2 border rounded"
            type="text"
            placeholder="Lien de l'image de couverture"
            {...register('coverImage.link')}
          />

          {fields.map((field, index) => {
            // Watch the imagePosition for this specific paragraph
            const imagePosition = watch(`paragraphs.${index}.imagePosition`);

            return (
              <div key={field.id} className="mb-4">
                <textarea
                  className="w-full p-2 border rounded"
                  placeholder={`Paragraphe ${index + 1}`}
                  {...register(`paragraphs.${index}.text`, { required: 'Le texte du paragraphe est requis' })}
                ></textarea>
                {errors.paragraphs && errors.paragraphs[index]?.text && (
                  <p className="text-red-500">{errors.paragraphs[index].text.message}</p>
                )}

                <select
                  className="w-full mb-4 p-2 border rounded"
                  {...register(`paragraphs.${index}.imagePosition`)}
                >
                                <option value="avant">Au-dessus</option>
                                <option value="après">En-dessous</option>
                                <option value="aucune">pas d'image</option>
                </select>

                {/* Show image link and description fields only if imagePosition is "avant" or "après" */}
                {(imagePosition === 'avant' || imagePosition === 'après') && (
                  <>
                    <input
                      className="w-full mb-2 p-2 border rounded"
                      type="text"
                      placeholder="Lien de l'image"
                      {...register(`paragraphs.${index}.imageLink`)}
                    />
                    <input
                      className="w-full mb-2 p-2 border rounded"
                      type="text"
                      placeholder="Description de l'image"
                      {...register(`paragraphs.${index}.imageDescription`)}
                    />
                  </>
                )}
              </div>
            );
          })}

          <div className="flex justify-between mb-4">
            <button
              type="button"
              className="bg-green-500 text-white px-4 py-2 rounded"
              onClick={() => append({ text: '', imageLink: '', imageDescription: '', imagePosition: 'aucune' })}
            >
              Ajouter un paragraphe
            </button>
            <button
              type="button"
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={() => remove(fields.length - 1)}
              disabled={fields.length === 1}
            >
              Supprimer le dernier paragraphe
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
              onClick={onClose}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={loading}
            >
              {loading ? 'Envoi en cours...' : 'Envoyer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}



 const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      id
      Titles
      images
      userID
      rubrique
      Paragraphs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

 const createImages = /* GraphQL */ `
  mutation CreateImages(
    $input: CreateImagesInput!
    $condition: ModelImagesConditionInput
  ) {
    createImages(input: $input, condition: $condition) {
      id
      link
      descriptions
      positions
      paragraphID
      createdAt
      updatedAt
      __typename
    }
  }
`;

 const createParagraph = /* GraphQL */ `
  mutation CreateParagraph(
    $input: CreateParagraphInput!
    $condition: ModelParagraphConditionInput
  ) {
    createParagraph(input: $input, condition: $condition) {
      id
      text
      title
      articlesID
      order
      Images {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
