
  const extractImageSrc = (html)=> {
    const imgSrcRegex = /<img[^>]+src="([^">]+)"/;
    const match = html.match(imgSrcRegex);
    return match ? match[1] : null;
  }

  const isImageLinkFormat = (html)=> {
    const linkFormatRegex = /<a\s+href="[^"]+"\s*>(<img\s+src="[^"]+"\s*[^>]*>)<\/a>/;
    return linkFormatRegex.test(html);
  }

 export const processImageLink = (link) => {
    return isImageLinkFormat(link) ? extractImageSrc(link) : link;
  };
  
 export function generateId() {
    return crypto.randomUUID(); // For modern browsers and Node.js
}