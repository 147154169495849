import React, { useState, useEffect } from 'react';
import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Link } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { listUSERS } from '../graphql/queries'; // Importer la requête pour lister les utilisateurs

Amplify.configure(awsExports);

export default function Login() {
  const [userID, setUserID] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const client = generateClient();

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const { userId } = await getCurrentUser(); // Récupérer l'ID de l'utilisateur connecté
        setUserID(userId);

        // Requête pour vérifier si l'utilisateur est un admin
        const listUsersResponse = await client.graphql({ query: listUSERS });
        const currentUser = listUsersResponse.data.listUSERS.items.find(user => user.logid === userId);
        
        if (currentUser) {
          setIsAdmin(currentUser.admin); // Définir le statut admin en fonction de l'utilisateur récupéré
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID de l'utilisateur ou du statut admin :", error);
      }
    };

    fetchUserId();
  }, []);

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div className="bg-white p-6 rounded-lg shadow-md">
 
            
            {/* Afficher le message en fonction de l'accès de l'utilisateur */}
            {isAdmin ? (
              <div className="mt-4">
                <p className="text-xl text-green-600">Vous avez des droits d'administrateur !</p>
                <Link to='/editorMasterRaceLinkPro' className="text-blue-500 hover:underline text-lg">Accès à l'éditeur</Link>
              </div>
            ) : (
              <div className="mt-4">
                <p className="text-xl text-red-600">Vous n'avez pas les droits d'administrateur.</p>
                <Link to='/' className="mt-4 text-blue-500 hover:underline text-lg">Accueil</Link>
              </div>
            )}
          </div>
        </div>
      )}
    </Authenticator>
  );
}
