import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { generateClient } from 'aws-amplify/api';
import {
    createParagraph,
    deleteParagraph,
    updateArticles,
    updateParagraph,
    createImages,
    updateImages,
    deleteImages
} from '../../graphql/mutations';
import { processImageLink } from '../../utils';

export default function EditModal({ isOpen, onClose, article, userId, fetchArticles }) {
    const { register, handleSubmit, control, watch, formState: { errors }, reset } = useForm({
        defaultValues: {
            title: '',
            rubrique: '',
            paragraphs: [{ text: '', imageLink: '', imageDescription: '', imagePosition: 'aucune', id: '' }],
            coverImage: { link: '' }
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'paragraphs',
    });

    const client = generateClient();
    const [loading, setLoading] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const TopTabArray = ["Actualité", "Nouveauté", "Portrait", "Chronique", "Agenda"];
    
    useEffect(() => {
        if (article) {
            const { Titles = '', rubrique = '', images = [], Paragraphs = { items: [] } } = article;
    
            reset({
                title: Titles,
                rubrique: rubrique,
                imageLink: images,
                paragraphs: Paragraphs.items.map(paragraph => {
                    const { text = '', id = '', Images = { items: [] } } = paragraph;
                    const { link = '', id: imageId = '', descriptions = '', positions = '' } = Images.items[0] || {};
                    
                    return {
                        text: text,
                        id: id,
                        imageLink: link,
                        imageId: imageId,
                        imageDescription: descriptions,
                        imagePosition: positions,
                    };
                })
            });
        }
    }, [article, reset]);
    
    

    if (!isOpen) return null;

    const handleAddParagraph = () => {
        append({ text: '', imageLink: '', imageDescription: '', imagePosition: 'aucune', id: null });
    };

    const onSubmitForm = async (data) => {
        setLoading(true);
        setSubmissionError(null);

        if (!userId) {
            setSubmissionError("L'ID utilisateur est requis. Assurez-vous que vous êtes connecté.");
            setLoading(false);
            return;
        }

        try {
            const updatedArticle = {
                id: article.id,
                Titles: data.title,
                rubrique: data.rubrique,
                images: data.imageLink,
            };
 
            const updateResponse = await client.graphql({
                query: updateArticles,
                variables: { input: updatedArticle },
            });

            const articleId = updateResponse.data.updateArticles.id;

            for (let i = 0; i < data.paragraphs.length; i++) {
                const paragraph = data.paragraphs[i];
                const paragraphData = {
                    id: paragraph.id || null,
                    text: paragraph.text,
                    title: `Paragraphe ${i + 1}`,
                    order: i + 1,
                    articlesID: articleId,
                };
 
                let paragraphResponse;
                if (paragraphData.id) {
                    paragraphResponse = await client.graphql({
                        query: updateParagraph,
                        variables: { input: paragraphData },
                    });
                } else {
                    const newParagraphData = {
                        text: paragraph.text,
                        title: `Paragraphe ${i + 1}`,
                        order: i + 1,
                        articlesID: articleId,
                    };
                    paragraphResponse = await client.graphql({
                        query: createParagraph,
                        
                        variables: { input: newParagraphData },
                    });
                }

                const paragraphId = paragraphResponse.data.createParagraph?.id || paragraphData.id;

                if (paragraph.imageLink) {

                    if(paragraph.imagePosition==="aucune"){
                        await client.graphql({
                            query:deleteImages,
                            variables: { input: { id: paragraph.imageId } },
    
                        })
                    }else{

                        const imageData = {
                            link: processImageLink(paragraph.imageLink),
                            descriptions: paragraph.imageDescription || "",
                            positions: paragraph.imagePosition,
                            paragraphID: paragraphId,
                        };
     
                        if (paragraph.imageId) {
                            await client.graphql({
                                query: updateImages,
                                variables: { input: imageData },
                            });
                        } else {
    
                            await client.graphql({
                                query: createImages,
                                variables: { input: imageData },
                            });
                        }
                    }

   
                }
            }
            fetchArticles()
            onClose();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l’article:', error);
            setSubmissionError('Échec de la mise à jour de l’article. Veuillez réessayer.');
        } finally {
            setLoading(false);
        }
    };

    const deleteParagraph = async ()=>{
        const lastElement = fields.at(-1);

        await client.graphql({
            query: deleteImages,
            variables: { input: { id: lastElement.id } },
        });

        await client.graphql({
            query: deleteParagraph,
            variables: { input: { id: lastElement.imageId } },
        });

    }

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-md w-full max-w-lg">
                <h2 className="text-2xl font-bold mb-4">Edit Article</h2>
                {submissionError && <p className="text-red-500 mb-4">{submissionError}</p>}

                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                        <input
                            id="title"
                            className="w-full mt-1 p-2 border rounded"
                            type="text"
                            placeholder="Title"
                            {...register('title', { required: 'Title is required' })}
                        />
                        {errors.title && <p className="text-red-500">{errors.title.message}</p>}
                    </div>

                    <div className="mb-4">
                        <label htmlFor="imageLink" className="block text-sm font-medium text-gray-700">couverture d'article</label>
                        <input
                            id="imageLink"
                            className="w-full mt-1 p-2 border rounded"
                            type="text"
                            placeholder="Image Link"
                            {...register('imageLink')}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="rubrique" className="block text-sm font-medium text-gray-700">Rubrique</label>
                        <select
                            id="rubrique"
                            className="w-full mt-1 p-2 border rounded"
                            {...register('rubrique', { required: 'Rubrique is required' })}
                        >
                            <option value="">Select Rubrique</option>
                            {TopTabArray.map((rubrique, index) => (
                                <option key={index} value={rubrique}>
                                    {rubrique}
                                </option>
                            ))}
                        </select>
                        {errors.rubrique && <p className="text-red-500">{errors.rubrique.message}</p>}
                    </div>

                    {fields.map((field, index) => {
                        const imagePosition = watch(`paragraphs.${index}.imagePosition`);
                        return (
                            <div key={field.id} className="mb-4">
                                <textarea
                                    className="w-full p-2 border rounded"
                                    placeholder={`Paragraphe ${index + 1}`}
                                    {...register(`paragraphs.${index}.text`, { required: 'Le texte du paragraphe est requis' })}
                                ></textarea>
                                {errors.paragraphs && errors.paragraphs[index]?.text && (
                                    <p className="text-red-500">{errors.paragraphs[index].text.message}</p>
                                )}

                                <select
                                    className="w-full mb-4 p-2 border rounded"
                                    {...register(`paragraphs.${index}.imagePosition`)}
                                >
                                    <option value="avant">Au-dessus</option>
                                    <option value="après">En-dessous</option>
                                    <option value="aucune">pas d'image</option>
                                </select>

                                {(imagePosition === 'avant' || imagePosition === 'après') && (
                                    <>
                                        <input
                                            className="w-full mb-2 p-2 border rounded"
                                            type="text"
                                            placeholder="Lien de l'image"
                                            {...register(`paragraphs.${index}.imageLink`)}
                                        />
                                        <input
                                            className="w-full mb-2 p-2 border rounded"
                                            type="text"
                                            placeholder="Description de l'image"
                                            {...register(`paragraphs.${index}.imageDescription`)}
                                        />
                                    </>
                                )}
                            </div>
                        );
                    })}

                    <div className="flex justify-between mb-4">
                        <button
                            type="button"
                            className="bg-green-500 text-white px-4 py-2 rounded"
                            onClick={handleAddParagraph}
                        >
                            ajouter un Paragraphe
                        </button>
                        <button
                            type="button"
                            className="bg-red-500 text-white px-4 py-2 rounded"
                            onClick={() => deleteParagraph()} 
                            disabled={fields.length === 1}
                        >
                            retirer un Paragraphe
                        </button>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 ${loading ? 'cursor-wait' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button
                            type="button"
                            onClick={onClose}
                            className="ml-2 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-700"
                        >
                            Cancel
                        </button>

 
                    </div>
                </form>
            </div>
        </div>
    );
}
