import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import EditCard from '../components/EditCard';
import Modal from '../components/Modal';
import EditModal from '../components/EditModal';
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getArticles, listUSERS } from '../../graphql/queries';
import { deleteArticles } from '../../graphql/mutations';

export default function Main() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [articles, setArticles] = useState([]);
  const [userID, setUserID] = useState(null);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [search, setSearch] = useState('');
  const client = generateClient();

  const fetchArticles = async () => {
    try {
      const listArticlesResponse = await client.graphql({ query: listArticles });
      setArticles(listArticlesResponse.data.listArticles.items);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const fetchUserId = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserID(userId);

      const listUsersResponse = await client.graphql({ query: listUSERS });
      const currentUser = listUsersResponse.data.listUSERS.items.find(user => user.logid === userId);
      setIsAdmin(currentUser.admin);
    } catch (error) {
      console.error("Error fetching user ID or admin status:", error);
    }
  };

  useEffect(() => {
    fetchUserId();
    fetchArticles();
  }, []);

  const handleAddArticle = (newArticle) => {
    setArticles([...articles, newArticle]);
    setIsModalOpen(false);
  };

  const handleEditArticle = (updatedArticle) => {
    setArticles(articles.map(article => 
      article.id === updatedArticle.id ? updatedArticle : article
    ));
    setIsEditModalOpen(false);
  };

  const handleEditClick = (article) => {
    setCurrentArticle(article);
    setIsEditModalOpen(true);
  };

  const handleDeleteArticle = async (articleId) => {
    if (!isAdmin) {
      alert("Vous n'avez pas la permission de supprimer cet article.");
      return;
    }

    // Confirmation alert in French
    const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?");
    if (!confirmation) {
      return; // Exit the function if the user clicks "Cancel"
    }

    try {
      await client.graphql({
        query: deleteArticles,
        variables: { input: { id: articleId } },
      });
      setArticles(articles.filter(article => article.id !== articleId));
    } catch (error) {
      console.error("Erreur lors de la suppression de l'article :", error);
    }
  };

  const filteredArticles = articles.filter(article => 
    article.Titles.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <main>
      <Header isAdmin={isAdmin} pageName={"Articles"} search={search} setSearch={setSearch} />
      <div className="flex justify-center mt-4">
        <div className="w-full max-w-2xl mt-1">
          {filteredArticles.map((article, index) => (
            <EditCard 
              key={index} 
              article={article} 
              onEditClick={() => handleEditClick(article)} 
              onDeleteClick={() => handleDeleteArticle(article.id)} 
            />
          ))}
        </div>

        <button
          className="bg-black fixed bottom-10 right-10 hover:bg-gray-700 text-white text-5xl font-bold rounded-3xl w-14 h-14 text-center flex justify-center items-center"
          onClick={() => setIsModalOpen(true)}
        >
          <p className="mb-3">+</p>
        </button>
      </div>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleAddArticle}
          userId={userID}
        />
      )}

      {isEditModalOpen && currentArticle && (
        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditArticle}
          userId={userID}
          fetchArticles={fetchArticles}
          article={currentArticle}
        />
      )}
    </main>
  );
}

export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Titles
        images
        userID
        rubrique
        createdAt
        updatedAt
        __typename
        Paragraphs {
          items {
            createdAt
            id
            order
            text
            title
            Images {
              items {
                id
                descriptions
                link
                positions
                __typename
              }
            }
            __typename
          }
        }
      }
      nextToken
      __typename
    }
  }
`;
