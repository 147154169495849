import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import { useParams } from 'react-router-dom';
import Card from '../Components/Card';
import Footers from '../Components/Footers';
import { generateClient } from 'aws-amplify/api';
import { listArticles } from '../graphql/queries';

export default function Rubrique() {
  const { pageName } = useParams();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const client = generateClient();

  const getArticles = async () => {
    try {
      const listArticlesResponse = await client.graphql({
        query: listArticles,
      });
      const allArticles = listArticlesResponse.data.listArticles.items;
      setArticles(allArticles);

      // Filter articles by rubrique (category) matching the pageName
      const rubriqueArticles = allArticles.filter(article => article.rubrique === pageName);
      setFilteredArticles(rubriqueArticles);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  if (loading) {
    return <p>Loading articles...</p>; // Loading message
  }

  return (
    <main className="flex flex-col min-h-screen">
      <Header pageName={pageName} />
      <h1 className='text-left text-4xl font-extrabold m-5 mt-10'>{pageName}</h1>
      <div className='p-2 w-full max-w-2xl mt-1 flex-grow'>
        {filteredArticles.length > 0 ? (
          filteredArticles.map(article => (
            <Card key={article.id} article={article} />
          ))
        ) : (
          <p>No articles found for this rubrique.</p> // Message when no articles are found
        )}
      </div>
      {/* Footer */}
      <Footers />
    </main>
  );
}
