import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { listUSERS } from './graphql/queries';
import { createUSER } from './graphql/mutations';

import Home from './screens/Home';
import Rubrique from './screens/Rubrique';
import ArticlePage from './screens/ArticlePage';
import Login from './screens/Login';
import AgendaPage from './screens/AgendaPage';
import ContactPage from './screens/contact';
import ProposPage from './screens/propos';

import EditRubrique from './editor/screens/EditRubrique';
import EditCover from './editor/screens/EditCover';
import EditAgenda from './editor/screens/EditAgenda';
import AdminPage from './editor/screens/AdminPage';
import MessagePage from './editor/screens/MessagePage';
import Main from './editor/screens/main';

function App() {
  const [userID, setUserID] = useState(null);
  const [isEditor, setIsEditor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const client = generateClient();

  const isUserAuth = async () => {
    try {
      const { userId } = await getCurrentUser();
      if (userId) {
        setUserID(userId);

        const listUsersResponse = await client.graphql({
          query: listUSERS,
        });

        const existingUser = listUsersResponse.data.listUSERS.items.find(user => user.logid === userId);
        if (existingUser) {
          setIsAdmin(existingUser.admin);
          setIsEditor(existingUser.editor);
        } else {
          await client.graphql({
            query: createUSER,
            variables: {
              input: { logid: userId },
            },
          });
          setIsAdmin(false);
          setIsEditor(false);
        }
      }
    } catch (error) {
      console.error("Error fetching or creating user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    isUserAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Rubrique/:title' element={<Rubrique />} />
        <Route path='/article/:title/:id' element={<ArticlePage />} />
        <Route path='/supelogin' element={<Login />} />
        <Route path='/Agenda' element={<AgendaPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/Apropos' element={<ProposPage />} />

        {/* Routes accessible only to Admins */}
        {isAdmin && (
          <>
            <Route path='/adminPage' element={<AdminPage />} />
            <Route path='/editorMasterRaceLinkPro-EditRubrique' element={<EditRubrique />} />
            <Route path='/editorMasterRaceLinkPro-EditCover' element={<EditCover />} />
            <Route path='/editorMasterRaceLinkPro' element={<Main />} />
            <Route path='/message-master-ace' element={<MessagePage />} />
            <Route path='/agenda-master-ace-agenda-journal' element={<EditAgenda />} />
          </> 
        )}

        {/* Routes accessible only to Editors */}
        {isEditor && (
          <>
            <Route path='/editorMasterRaceLinkPro-EditRubrique' element={<EditRubrique />} />
            <Route path='/editorMasterRaceLinkPro-EditCover' element={<EditCover />} />
            <Route path='/editorMasterRaceLinkPro' element={<Main />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;