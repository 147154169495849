import React from 'react';
import { Link } from 'react-router-dom';

export default function Footers({ marginTop }) {
  const finalMarginTop = marginTop ?? 24; // Default to 24 if marginTop is null or undefined

  return (
    <footer className={`bg-black text-white py-4 w-full mt-${finalMarginTop}`}>
      <div className="container mx-auto text-center">
        <p className="text-lg font-semibold">lalecturejecontribue</p>
        <p className="text-sm mt-1">© 2024 lalecturejecontribue. All rights reserved.</p>
        <div className="mt-4">
          <Link to="/Apropos" className="text-white mx-2 hover:underline">À propos</Link>
          <Link to="/contact" className="text-white mx-2 hover:underline">Contact</Link>
        </div>
        <div className="mt-4 flex justify-center space-x-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}
