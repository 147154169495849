import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import Header from '../Components/Header';
 
export default function ArticlePage() {
  const { title, id } = useParams(); 
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = generateClient();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const result = await client.graphql({
          query: getArticles,
          variables: { id: id },
        });
        setArticle(result.data.getArticles);
        console.log(result)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false);
      }
    };

    console.log(id)
    if(id){
      fetchArticle();

    }

   }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!article) {
    return <p>Article not found.</p>;
  }

  return (
    <main className="pl-2 pr-2">
      <Header />
      <img src={article.images} alt={article.Titles} className="w-full h-72 mt-2 rounded-xl" />
      <h1 className="text-4xl font-bold mt-4">{article.Titles}</h1>
      <div className="mt-4">
        {article.Paragraphs?.items.map((paragraph) => (
          <div key={paragraph.id} className="mb-4">
            {paragraph.Images?.items[0]?.positions === "avant" && (
              <div className="mb-2">
                <img src={paragraph.Images.items[0].link} alt={paragraph.Images.items[0].descriptions} className="w-full rounded-md" />
                {paragraph.Images.items[0].descriptions && (
                  <p className="text-sm italic text-gray-600 mt-1">{paragraph.Images.items[0].descriptions}</p>
                )}
              </div>
            )}

            <p className="text-lg">{paragraph.text}</p>

            {paragraph.Images?.items[0]?.positions === "après" && (
              <div className="mt-2">
                <img src={paragraph.Images.items[0].link} alt={paragraph.Images.items[0].descriptions} className="w-full rounded-md" />
                {paragraph.Images.items[0].descriptions && (
                  <p className="text-sm italic text-gray-600 mt-1">{paragraph.Images.items[0].descriptions}</p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Footer */}
      <footer className="bg-black text-white py-4">
        <div className="container mx-auto flex flex-col items-center">
          <p className="text-lg font-semibold">lalecturejecontribue</p>
          <p className="text-sm mt-1">© 2024 lalecturejecontribue. All rights reserved.</p>
          <div className="mt-4">
            <Link to="/Apropos" className="text-white mx-2 hover:underline">À propos</Link>
            <Link to="/contact" className="text-white mx-2 hover:underline">Contact</Link>
          </div>
          <div className="mt-4 flex justify-center space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}

export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
        id
        Titles
        images
        userID
        rubrique
        createdAt
        updatedAt
        __typename
        Paragraphs {
          items {
            createdAt
            id
            order
            text
            title
            Images {
              items {
                id
                descriptions
                link
                positions
                __typename
              }
            }
            __typename
          }
      }
    }
  }
`;